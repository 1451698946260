.title {
    font-size: 20px;
    font-weight: 400;
}
.footer {
    margin-top: 20px;
}
.recorType {
    margin-top: 20px;
}
.recordTypeTitle {
    color: rgba(0,0,0,.85);
    overflow-wrap: break-word;
    font-size: 20px;
    margin-top: 20px;
}