body {
  background: #e6f2ff;
}
.h100 {
  height: 100%;
}
.subtitle {
  font-size: 18px;
  margin-bottom: 10px;
  display: inline-block;
}
.select {
  width: 100%;
  text-align: left;
}
.input_wrapper {
  margin-bottom: 25px;
  width: 100%;
}
.form {
  margin: 30px 0;
}

.captcha {
  margin-bottom: 15px;
}
.react-simple-keyboard {
  position: fixed;
  bottom: 20%;
  width: 70%!important;
  max-width: 1000px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10000;
}

.react-simple-keyboard.hg-theme-default .hg-button-enter {
  background-color: #40a9ff;
  color: #fff;
}
.hg-functionBtn {
  width: 20px;
}

@media (max-width: 576px) {
  .title {
    font-size: 18px;
  }
  .subtitle {
    font-size: 16px;
  }
  .input_wrapper {
    margin-bottom: 10px;
  }
}

@media (max-width: 400px) {
  .ant-segmented-item-label {
    font-size: 14px;
    white-space: normal;
  }
}