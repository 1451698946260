.form {
    width: 540px;
}
@media (max-width: 560px) {
    .form {
        width: 400px;
    }
}
@media (max-width: 420px) {
    .form {
        width: 300px;
    }
}
.title {
    font-size: 20px;
}
.footer {
    margin-top: 20px;
}
.recorType {
    margin-top: 20px;
}
.recordTypeTitle {
    color: rgba(0,0,0,.85);
    overflow-wrap: break-word;
    font-size: 18px;
    margin: 20px 0 10px 0;
    display: inline-block;
}