.content {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 15px;
}
.main {
  background-color: #fff;
  flex: 1 1 auto;
  border-radius: 10px;
  margin: 10px 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 15px;
  text-align: center;
}

@media (max-width: 576px) {
  .content {
    padding: 10px 5px;
  }
  .main {
    border-radius: 0;
    padding: 8px;
    margin: 5px 0;
  }
}
